// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-cookies-js": () => import("/opt/build/repo/src/pages/about-cookies.js" /* webpackChunkName: "component---src-pages-about-cookies-js" */),
  "component---src-pages-company-old-team-js": () => import("/opt/build/repo/src/pages/company.old/team.js" /* webpackChunkName: "component---src-pages-company-old-team-js" */),
  "component---src-pages-ieo-exclusive-airdrop-old-index-js": () => import("/opt/build/repo/src/pages/ieo-exclusive-airdrop.old/index.js" /* webpackChunkName: "component---src-pages-ieo-exclusive-airdrop-old-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-old-chainkit-js": () => import("/opt/build/repo/src/pages/products.old/chainkit.js" /* webpackChunkName: "component---src-pages-products-old-chainkit-js" */),
  "component---src-pages-products-old-framework-js": () => import("/opt/build/repo/src/pages/products.old/framework.js" /* webpackChunkName: "component---src-pages-products-old-framework-js" */),
  "component---src-pages-products-old-platform-js": () => import("/opt/build/repo/src/pages/products.old/platform.js" /* webpackChunkName: "component---src-pages-products-old-platform-js" */),
  "component---src-pages-pto-exclusive-airdrop-old-index-js": () => import("/opt/build/repo/src/pages/pto-exclusive-airdrop.old/index.js" /* webpackChunkName: "component---src-pages-pto-exclusive-airdrop-old-index-js" */),
  "component---src-pages-terms-of-service-js": () => import("/opt/build/repo/src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

